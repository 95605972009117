











































































































import { Component, Vue } from "vue-property-decorator";
import { forceAppUpdate } from "./registerServiceWorker";

interface MenuItem {
  title: string;
  to: string;
}

@Component({})
export default class App extends Vue {
  user: unknown = null;
  drawer = false;

  dynamicMenuItems: MenuItem[] = [];

  menuItems(): MenuItem[] {
    return [...this.dynamicMenuItems];
  }

  async fetchPlaces(token: string | undefined) {
    if (!token) {
      console.error("Token is undefined at the time of API call.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.VUE_APP_BASE_URL}/api/places`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const data = await response.json();
      this.dynamicMenuItems = data
        .map((place: any) => ({
          title: place.label,
          to: `/place/${place.place_id}`,
        }))
        .sort((a: { title: string }, b: { title: any }) =>
          a.title.localeCompare(b.title)
        );
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }

  async mounted() {
    await this.getUser();
    if (!(await this.$auth.isAuthenticated())) {
      console.error("User is not authenticated.");
      await this.$auth.signInWithRedirect();
    } else {
      const token = await this.getAccessToken();
      if (!token) {
        console.error("Failed to retrieve token.");
      } else {
        await this.fetchPlaces(token);
      }
    }
  }

  get isProductionMode(): boolean {
    return process.env.NODE_ENV === "production";
  }

  async login(): Promise<void> {
    await this.$auth.signInWithRedirect();
  }

  async logout(): Promise<void> {
    await this.$auth.signOut();
  }

  async getUser(): Promise<void> {
    if (await this.$auth.isAuthenticated()) {
      this.user = this.$auth.authStateManager.getAuthState().idToken?.claims;
    }
  }

  async getAccessToken(): Promise<string | undefined> {
    const accessToken = this.$auth.getAccessToken();
    if (!accessToken) {
      console.log("getAccessToken returned undefined.");
    }
    return accessToken || undefined;
  }

  updateApp(): void {
    forceAppUpdate();
  }
}
