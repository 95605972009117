<template>
  <div class="doors-container text-center">
    <div v-if="!isYourOffice" class="mb-5">
      Horaires : {{ formatTime(place.start_service_time) }} -
      {{ formatTime(place.end_service_time) }}
    </div>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    <div v-else>
      <div
        class="doors-container"
        v-if="isWithinServiceHoursOrMatchingOfficeOrAdmin"
      >
        <li v-for="door in doors" :key="door.id">
          <v-btn @click="openDoor(door)" class="dark-text">{{
            door.label
          }}</v-btn>
          <p>{{ message }}</p>
        </li>
      </div>
      <div v-else>
        L'ouverture des portes est indisponible en dehors des horaires de
        service
      </div>
    </div>
  </div>
</template>

<script>
import { fetchAssociatedOffice } from "../../utils";

export default {
  name: "DoorsList",
  props: {
    place: {
      type: Object,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      message: "",
      errorMessage: "",
      doors: [],
      associatedOfficeId: null,
      userLocation: { latitude: null, longitude: null },
      geolocationDenied: false,
      is_admin: false,
    };
  },
  computed: {
    isWithinServiceHours() {
      const currentTime = new Date();
      const startTime = new Date();
      const endTime = new Date();

      const [startHours, startMinutes] =
        this.place.start_service_time.split(":");
      const [endHours, endMinutes] = this.place.end_service_time.split(":");

      startTime.setHours(startHours, startMinutes, 0, 0);
      endTime.setHours(endHours, endMinutes, 0, 0);

      return currentTime >= startTime && currentTime <= endTime;
    },
    isYourOffice() {
      return this.associatedOfficeId === this.place.place_id;
    },
    isWithinServiceHoursOrMatchingOfficeOrAdmin() {
      return this.isWithinServiceHours || this.isYourOffice || this.is_admin;
    },
  },
  methods: {
    formatTime(timeString) {
      return timeString.slice(0, 5);
    },
    fetchDoors() {
      const url = `${process.env.VUE_APP_BASE_URL}/api/doors_by_place_id/${this.place.place_id}`;
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          let mainDoors = data.filter((door) => door.is_main_door);
          let otherDoors = data.filter((door) => !door.is_main_door);
          mainDoors.sort((a, b) => a.label.localeCompare(b.label));
          otherDoors.sort((a, b) => a.label.localeCompare(b.label));
          this.doors = mainDoors ? [...mainDoors, ...otherDoors] : otherDoors;
        })
        .catch((error) => {
          this.errorMessage = `Error fetching doors: ${error.message}`;
          console.error("Error fetching doors:", error);
        });
    },

    async is_user_admin() {
      const url = `${process.env.VUE_APP_BASE_URL}/api/is_admin`;
      const headers = new Headers({
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "application/json",
      });
      const body = JSON.stringify({
        place_name: `${this.place.name}`,
      });
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: body,
        });
        const result = await response.json();
        this.is_admin = result;
      } catch (error) {
        console.error("Error opening door:", error);
      }
    },

    async openDoor(door) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/request_opening_from_jwt`;
      const headers = new Headers({
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "application/json",
      });
      const body = JSON.stringify({
        door: `${this.place.name}.${door.name}`,
      });

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: headers,
          body: body,
        });

        if (!response.ok) {
          this.errorMessage = `Error opening door: ${response.statusText}`;
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        this.errorMessage = "";
      } catch (error) {
        console.error("Error opening door:", error);
        await this.$auth.signInWithRedirect();
      }
    },
  },
  async mounted() {
    this.fetchDoors();
    this.is_user_admin();

    try {
      this.associatedOfficeId = await fetchAssociatedOffice(this.token);
    } catch (error) {
      console.error("Error fetching associated office:", error);
    }
  },
};
</script>

<style scoped>
.doors-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.doors-container li {
  list-style-type: none;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
}

.dark-text {
  color: #17236b;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.geolocation-denied {
  color: red;
}

.retry-btn {
  background-color: #17236b;
  color: white;
  margin-top: 10px;
}
</style>
