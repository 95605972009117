























import { Vue, Component, Watch } from "vue-property-decorator";
import DoorsList from "./components/DoorsList.vue";

@Component({
  components: {
    DoorsList,
  },
})
export default class PlaceDetails extends Vue {
  placeId: string = this.$route.params.placeId;
  placeDetails: any = null;
  token: string | undefined = "";

  @Watch("$route.params.placeId", { immediate: true, deep: true })
  async onPlaceIdChanged(newVal: string, oldVal: string) {
    if (newVal !== oldVal) {
      await this.refreshPlaceDetails(newVal);
    }
  }

  get componentKey() {
    return `doors-${this.placeDetails.place_id}-${Date.now()}`;
  }

  get isReadyToRender() {
    return this.placeDetails !== null && this.token !== "";
  }

  update() {}

  async mounted() {
    await this.refreshPlaceDetails(this.placeId);
    this.isReadyToRender;
  }

  async refreshPlaceDetails(placeId: string) {
    if (!(await this.$auth.isAuthenticated())) {
      console.error("User is not authenticated.");
      await this.$auth.signInWithRedirect();
      return;
    }

    this.token = await this.getAccessToken();
    if (!this.token) {
      console.error("Failed to retrieve token.");
      return;
    }

    await this.fetchPlaceDetails(this.token, placeId);
  }

  async fetchPlaceDetails(token: string, placeId: string) {
    const url = `${process.env.VUE_APP_BASE_URL}/api/place/${placeId}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      this.placeDetails = await response.json();
    } catch (error) {
      console.error("Error fetching place details:", error);
      this.placeDetails = {};
    }
  }

  async getAccessToken(): Promise<string | undefined> {
    const accessToken = await this.$auth.getAccessToken();
    if (!accessToken) {
      console.log("getAccessToken returned undefined.");
      return undefined;
    }
    return accessToken;
  }

  get imagePath(): string {
    return this.placeDetails ? `/img/place/${this.placeDetails.name}.svg` : "";
  }
}
